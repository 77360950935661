.untraceable-screen {
  padding: 5rem 0;

  .frame-container,
  .logo-container {
    padding: 20px;
    background: #ececec;
    border-radius: 15px;
    margin-bottom: 50px;
  }

  .logo-container {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      height: 35px;
      width: 15px;
      right: 50%;
      bottom: -45px;
      margin-top: -22px;
      background: url(../img/arrow-right.png) no-repeat;
      background-size: contain;
      transform: rotate(90deg);
    }

    &.center:after {
      right: 50%;
    }

    &.left:after {
      right: 83%;
    }

    &.right:after {
      right: 17%;
    }

    .logo-button {
      opacity: 0.4;
      padding: 20px 0;
    }

    .logo-button div {
      min-height: 90px;
    }

    .logo-button span {
      color: #445e5c;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 400;
      padding: 3px;
    }

    .logo-button.active,
    .logo-button:hover {
      opacity: 1;
    }

    .logo-button.active a,
    .logo-button:hover a {
      text-decoration: none;
    }

    .logo-button.active a span,
    .logo-button:hover a span {
      color: #aa9059;
      border-bottom: 2px solid #aa9059;
    }
  }

  .slide-container {
    position: relative;
  }

  .slide-container .slide {
    margin: 20px 0;
  }

  .image-container {
    display: none;
  }

  .image-container.active {
    display: block;
  }

  button {
    border: none;
    background: #d3d7d7;
    color: #445e5c;
    text-transform: uppercase;
    border-radius: 15px;
    padding: 15px 30px;
    font-size: 1.4rem;
    line-height: 1;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  button.active,
  button:hover {
    background: #445e5c;
    color: #fff;
  }

  #copy-button {
    background: 0 0;
    border: 1px solid #445e5c;
    position: relative;
    transition: all 0.5s;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  #copy-button.zeroclipboard-is-hover {
    background: #445e5c;
    color: #fff;
  }

  #copy-button.zeroclipboard-is-active:before {
    content: '';
    opacity: 1;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #445e5c transparent transparent;
    top: -10px;
    left: 37px;
  }

  #copy-button.zeroclipboard-is-active:after {
    opacity: 1;
    content: 'Saved to clipboard';
    background: #445e5c;
    color: #fff;
    font-weight: 100;
    font-size: 1rem;
    line-height: 1;
    padding: 10px 15px;
    border-radius: 10px;
    position: absolute;
    top: -40px;
    width: 160%;
    left: -30%;
    text-transform: capitalize;
  }
}