.navbar {
  padding-top: 30px;

  li a {
    color: #fff;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 5;
    padding: 10px 9px;
  }

  li.active a,
  li a:focus,
  li a:hover {
    background: none;
    color: #aa9059;
  }
}

.navbar-brand img {
  padding-top: 10px;
}

.navbar-nav li.menu-title {
  display: none;
}

.navbar-toggle {
  z-index: 900;
}

@media (max-width: 1000px) {
  .navbar {
    padding: 0;
  }

  .navbar-brand {
    width: 150px;
  }

  .navbar-nav>li>a {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar-collapse {
    top: 0;
    width: 90%;
    left: 5%;
    margin: 0 !important;
    box-shadow: none;
    position: absolute;
    background: rgba(68, 92, 88, 0.97);
    z-index: 800;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;

    &.collapse {
      display: none !important;
      ;
    }

    &.collapse.in {
      display: block !important;
      ;
    }

    .navbar-nav {
      margin: 0;
    }

    ul li {
      padding: 0 9%;
    }

    ul li.menu-title {
      display: block;
      background: #242c2c;
    }

    ul li.menu-title a {
      text-shadow: 4px 4px 2px rgba(112, 97, 65, 0.6);
      border: none;
    }

    ul li a {
      font-size: 2.6rem;
      padding: 2rem 0;
      line-height: 1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    ul li:last-of-type a {
      border: none;
      padding-bottom: 4rem;
    }

    ul li:nth-of-type(2) a {
      padding-top: 4rem;
    }
  }

  .navbar-header,
  .navbar-nav,
  .navbar-nav li {
    float: none;
  }

  .navbar-header .navbar-toggle {
    margin-right: 6%;
    margin-top: 2%;
    display: block;

    &[aria-expanded='true'] .icon-bar {
      display: none;
    }

    &[aria-expanded='true'] .cross {
      display: block;
    }

    &:focus,
    &:hover {
      background: 0 0;
    }

    span.icon-bar {
      width: 28px;
      height: 4px;
      background-color: #fff;
    }

    .cross {
      display: none;
      color: #fff;
      font-weight: 700;
      font-size: 20px;
      line-height: 1;
    }
  }
}