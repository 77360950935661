.footer-screen {
  padding: 75px 0 25px;
  background: #1e2323;
  color: #fff;

  .menu-container {
    margin-bottom: 35px;

    .footer-logo {
      max-height: 50px;
    }

    .footer-navigation ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .footer-navigation ul li {
      display: inline;
      padding: 0 20px;
      border-right: 1px solid #fff;
    }

    .footer-navigation ul li:last-of-type {
      border: none;
    }

    .footer-navigation ul li a {
      color: #fff;
      font-size: 1.4rem;
      line-height: 3.57;
    }
  }
}